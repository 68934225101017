.ag-theme-material .ag-cell-inline-editing {
    height: 100% !important;
}

:root {
    --ag-value-change-value-highlight-background-color: #99ffb8;
}

.ag-cell-data-changed {
    background-color: #99ffb8 !important;
    background-color: var(--ag-value-change-value-highlight-background-color, #99ffb8) !important;
}

.ag-cell-data-changed-animation {
    background-color: #99ffb850 !important;
}


.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ag-theme-material .ag-rtl .ag-selection-checkbox {
    margin: 1px !important;
    padding-right: 10px !important;
    padding-left: 7px !important;
}

.ag-theme-material .ag-ltr .ag-selection-checkbox {
    margin: 1px !important;
    padding-right: 7px !important;
    padding-left: 10px !important;
}

.ag-theme-material .ag-header-cell {
    margin: 0px !important;
    padding-right: 12px !important;
    padding-left: 12px !important;
}

:root {
    --PhoneInputCountryFlag-height: 2em;
    --PhoneInputCountryFlag-borderColor--focus: #1dac48;

    /*To make the arrow disapear */
    --PhoneInputCountrySelectArrow-borderWidth: 0px;
}

input[type="tel"] {
    direction: ltr;
    width: fit-content;
    text-align: -webkit-match-parent;
}

.ag-paging-row-summary-panel {
    direction: ltr;
}